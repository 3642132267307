import "../styles/app/app.scss";
import React, { useEffect } from "react";
import routes from "./routes";
import { RouterProvider } from "react-router-dom";
import { useLazyGetTranslationDataQuery } from "./redux/translation/translationApi";
import i18n from 'i18next';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLazyGetUserInfoQuery } from "./redux/userManager/userManagerAPI";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError} from "@azure/msal-browser";
import { getToken } from "app/auth/getToken";
import { Logout } from "app/auth/logout";

function App(props) {
  const loginRedirectRequest = {
    extraQueryParameters: {
      "id_token_hint": localStorage.getItem('idTokenHint')
    }
  };

  const { accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated(InteractionType.Silent, loginRedirectRequest);
  const [getTranslationData] = useLazyGetTranslationDataQuery();
  const {selectedTranslationLang} = useSelector(state => state.translation);
  const [getuserInfo] = useLazyGetUserInfoQuery();
  let activeRefreshToken = sessionStorage.getItem('ActiveRefreshToken');

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;

    async function authenticateUser() {
      const loginRedirectRequest = {
        extraQueryParameters: {
          "id_token_hint": localStorage.getItem('idTokenHint')
        }
      };

      console.log("Logging in...");
      await props.pca.loginRedirect(loginRedirectRequest);
    }

    async function exchangeToken() {
      var token = await getToken();
      if (token === undefined) {
        await Logout();
      }
    }

    if (!isAuthenticated) {
      authenticateUser();
    }
    else
    {
      if (activeRefreshToken !== null || activeRefreshToken !== undefined) {
        exchangeToken();
      }
    }
    
    if (isAuthenticated) {
      if (localStorage.getItem('translation-en')) {
        i18n.addResourceBundle('en', 'translation', JSON.parse(localStorage.getItem('translation-en')), true, true);
      }

      if (localStorage.getItem('translation-no')) {
        i18n.addResourceBundle('no', 'translation', JSON.parse(localStorage.getItem('translation-no')), true, true);
      }

      getuserInfo();
      
      getTranslationData().then((result) => { 
        i18n.addResourceBundle('en', 'translation', JSON.parse(localStorage.getItem('translation-en')), true, true);
        i18n.addResourceBundle('no', 'translation', JSON.parse(localStorage.getItem('translation-no')), true, true);
      });
    }
  }, [isAuthenticated, inProgress, activeRefreshToken])

  if (isAuthenticated) {
    return (
      <>
        <RouterProvider router={routes} />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </>
    );
  } else {
    return <div className="App">Loggin in...</div>;
  }
}

export default App;
